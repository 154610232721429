<template>
  <a-table
    rowKey="orderNo"
    :data-source="list"
    :columns="columns"
    :pagination="false"
    bordered
  >
    <span slot="index" slot-scope="text, record, index">
      {{ index + 1 }}
    </span>
    <span slot="status" slot-scope="text">
      {{ text | status }}
    </span>
    <span slot="action" slot-scope="text, record">
      <router-link :to="`/b2b/order/detail?id=${text}&userId=${record.userId}`">详情</router-link>
    </span>
  </a-table>
</template>

<script>
import { status } from '@/filters/order'
import { format } from '@/filters/time'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '提交时间',
          dataIndex: 'createTime'
        },
        {
          title: '客户',
          dataIndex: 'submitter'
        },
        {
          title: '航班',
          dataIndex: 'flightNos'
        },
        {
          title: '航班日期',
          dataIndex: 'flightDates',
          customRender: (text) => {
            const dates = text.split('/').map(item => {
              return format(item)
            })

            return dates.join('/')
          }
        },
        {
          title: '数量',
          dataIndex: 'leftGroupQty'
        },
        {
          title: '单价',
          dataIndex: 'unitNetFare'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'orderNo',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  props: {
    list: Array
  },
  filters: {
    status,
    format
  }
}
</script>
