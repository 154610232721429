<template>
  <div class="detail-deposit-refund-container cell-inner">
    <div class="inner-body">
    <a-descriptions :column="3">
      <a-descriptions-item label="订单编号">
        {{ order.orderNo }}
      </a-descriptions-item>
      <a-descriptions-item label="总票款">
        ￥{{ order.statisticInfo.totalOrderAmount }}
      </a-descriptions-item>
      <a-descriptions-item label="订单状态">
        {{ order.status | status }}
      </a-descriptions-item>
    </a-descriptions>
    <a-table
      class="pay-detail-table"
      rowKey="id"
      :loading="loading"
      :data-source="list"
      :columns="columns"
      :pagination="false"
      bordered
    >
      <span slot="id" slot-scope="row">
        <a-radio
          :value="row.id"
          :checked="row.id === checkPay.id"
          :disabled="row.payStatus !== 'PAID'"
          @click="onCheckPay(row)"
        ></a-radio>
      </span>
      <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="payStatus" slot-scope="payStatus">
        {{ payStatus | payStatus }}
      </span>
      <span slot="payRate" slot-scope="payRate">
        {{ (payRate * 100) | rate }}
      </span>
    </a-table>
    <div class="detail-dialog-header">申请退押金</div>
    <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 15 }">
      <a-form-item label="申退原因">
        <a-select
          :options="reasonOptions"
          v-decorator="[
            'reasonOptions',
            { rules: [{ required: true, message: '请选择退票原因' }],
              initialValue: refundReason
             },
          ]"
        @change="onReasonChange"
        ></a-select>
      </a-form-item>
      <a-form-item label="退款类型">
        <a-select
          :options="refundUse"
          v-decorator="[
            'refundUse',
            { rules: [{ required: true, message: '请选择退款类型' }],
              initialValue: 'REFUND'
             },
          ]"

        ></a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-decorator="['descp']"></a-textarea>
      </a-form-item>
    </a-form>
    <div>退押金规定</div>
    <a-descriptions :column="3">
      <a-descriptions-item label="退票押金原因">
        {{ refundReason }}
      </a-descriptions-item>
      <a-descriptions-item label="手续费率">
        <span v-if="refundReason === '自愿退座'">{{ (this.fee.feeRate * 100 ) | rate }}</span>
        <span v-else>0%</span>
      </a-descriptions-item>
    </a-descriptions>
    <template v-if="refundReason === '自愿退座'">
      <div>退押金手续费规定</div>
      <a-descriptions :column="3">
        <a-descriptions-item label="团队折扣率区间">
          {{ fee.discountFrom | rate }}-{{ fee.discountTo | rate }}
        </a-descriptions-item>
        <a-descriptions-item label="距航班起飞时间">
          {{ fee.refundTimeFrom }}-{{ fee.refundTimeTo }}小时
        </a-descriptions-item>
        <a-descriptions-item label="手续费率">
        {{ (this.fee.feeRate * 100) | rate }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <div class="refund-notice">提交后，订单全部PNR编码将立即被清位，具体退款金额以航空公司后台审核为准。</div>
        </div>
    <div class="inner-footer">
     <a-button type="primary" :loading="submitLoading" @click="onSubmitClick">提交</a-button>
    </div>
  </div>
</template>

<script>
import { depositRefundApplyFlow, getDepositRefundFee } from '@/api/order'
import { status } from '@/filters/order'
import { status as payStatus } from '@/filters/pay'

const refundTypeMap = [
  { label: '航班取消', value: 'U' },
  { label: '团队位被征回', value: 'O' },
  { label: '自愿退座', value: 'C' },
  { label: '市场原因提价', value: 'H' },
  { label: '市场原因降价', value: 'L' }
]

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'deposit-refund' }),
      columns: [
        {
          title: '选择',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center'
        },
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '押金支付时限',
          dataIndex: 'limit'
        },
        {
          title: '支付比例',
          dataIndex: 'payRate',
          scopedSlots: { customRender: 'payRate' }

        },
        {
          title: '支付时间',
          dataIndex: 'createTime'
        },
        {
          title: '支付金额',
          dataIndex: 'totalFee'
        },
        {
          title: '支付状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' }

        }
      ],
      list: [],
      reasonOptions: [
        {
          label: '航班取消',
          value: '航班取消'
        },
        {
          label: '团队位被征回',
          value: '团队位被征回'
        },
        {
          label: '自愿退座',
          value: '自愿退座'
        },
        {
          label: '市场原因提价',
          value: '市场原因提价'
        }
      ],
      refundUse: [
        {
          label: '退款',
          value: 'REFUND'
        },
        {
          label: '冲抵',
          value: 'OFFSET'
        }
      ],
      loading: false,
      submitLoading: false,
      refundReason: '航班取消',
      refundFeeRate: '',
      checkPay: {},
      fee: {}
    }
  },
  props: {
    order: Object
  },
  mounted () {
    this.getDepositPayment()
  },
  methods: {
    async fetchDepositRefundFee () {
      try {
        const result = await getDepositRefundFee({
          orderId: this.order.orderId,
          id: this.checkPay.id,
          refundType: 'C' // 默认传自愿，其他非自愿费率都为前端写死0
        })
        this.fee = result
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    getDepositPayment (record) {
      this.list = this.order.uniDepositPayDTOS.map((item) => {
        return item
      })
    },
    onCheckPay (payRow) {
      this.checkPay = payRow
      this.fetchDepositRefundFee()
    },
    onSubmitClick () {
      if (!this.checkPay.id) {
        this.$message.error('请选择支付条目')
        return
      }
      const self = this
      this.$confirm({
        title: '温馨提示',
        content: '确认提交退押金申请？退押金后，系统将立即释放订单PNR清位，请仔细核对后确认操作。',
        okText: '提交退押金',
        onOk () {
          self.onSubmitRefund()
        },
        cancelText: '取消',
        onCancel () {}
      })
    },
    async onSubmitRefund () {
      const fields = this.form.getFieldsValue()

      try {
        this.submitLoading = true
        let params = {
          descp: fields.descp,
          orderId: this.order.orderId,
          id: this.checkPay.id,
          refundReason: fields.reasonOptions,
          refundType: refundTypeMap.filter((type) => { return fields.reasonOptions === type.label })[0].value,
          use: fields.refundUse
        }

        params = {
          audit: {
            action: 'START'
          },
          bizDataJson: JSON.stringify(params)
        }

        await depositRefundApplyFlow(params)
        this.submitLoading = false
        this.$message.success('订单预付款退款已提交申请，即将刷新页面', function () {
          location.reload()
        })
      } catch (e) {
        this.submitLoading = false
        this.$message.error(e.message)
      }
    },
    onReasonChange (value) {
      this.refundReason = value
    }
  },
  filters: {
    status,
    payStatus,
    rate: (value) => {
      return `${(value || 0)}%`
    }
  }
}
</script>

<style lang="less" scoped>

.pay-detail-table{
  margin-bottom: 15px;
}

.refund-notice {
  margin-bottom: 15px;
  color: red;
}
</style>
