import { parseToFilters, parseToMap } from './util'

export const certType = [
    { value: 'NI', label: '身份证' },
    { value: 'PP', label: '护照' },
    { value: 'PP1', label: '外籍旅客护照' },
    { value: 'JG', label: '军官证' },
    { value: 'NID', label: '外国人永久居留证' },
    { value: 'FG', label: '外国人永久居留证' },
    { value: 'WG', label: '外国人永久居留证' },
    { value: 'HM', label: '港澳台居民居住证' },
    { value: 'ID', label: '其他' },
    { value: 'ID1', label: '港澳通行证' },
    { value: 'ID2', label: '大陆居民往来台湾通行证' },
    { value: 'ID3', label: '文职干部证' },
    { value: 'ID4', label: '义务兵证' },
    { value: 'ID5', label: '士官证' },
    { value: 'ID6', label: '文职人员证' },
    { value: 'ID7', label: '职工证' },
    { value: 'ID8', label: '武警警官证' },
    { value: 'ID9', label: '武警士兵证' },
    { value: 'ID10', label: '海员证' },
    { value: 'ID11', label: '港澳居民来往内地通行证' },
    { value: 'ID12', label: '台湾居民来往大陆通行证' },
    { value: 'ID13', label: '外交部签发的驻华外交人员证' },
    { value: 'ID14', label: '外国人永久居留证' },
    { value: 'ID15', label: '出生医学证明' },
    { value: 'ID16', label: '户口簿' },
    { value: 'ID17', label: '学生证' },
    { value: 'ID18', label: '户口所在地公安机关出具的身份证明' },
    { value: 'ID19', label: '民航局规定的其他有效乘机身份证件' },
    { value: 'ID20', label: '外国人出入境证' },
    { value: 'ID21', label: '电子护照' },
    { value: 'ID26', label: '特殊身份证' },
    { value: 'UU', label: '无法识别证件' }
]

export const certTypeFilter = parseToFilters(certType)
export const certTypeMap = parseToMap(certType)

export const passengerType = [
    { value: 'ADT', label: '成人' },
    { value: 'CHD', label: '儿童' },
    { value: 'INF', label: '婴儿' }
]

export const passengerTypeFilter = parseToFilters(passengerType)
export const passengerTypeMap = parseToMap(passengerType)

export const genderType = [
    { label: '男', value: 'M'},
    { label: '女', value: 'F'}
]

export const nationalType = [
    { value: "CN", label: "中国"},
    { value: "HK", label: "香港（中国）"},
    { value: "TW", label: "台湾（中国）"},
    { value: "MO", label: "澳门（中国）"},
    { value: "US", label: "美国"},
    { value: "AF", label: "阿富汗"},
    { value: "AL", label: "阿尔巴尼亚"},
    { value: "DZ", label: "阿尔及利亚"},
    { value: "AS", label: "美属萨摩亚"},
    { value: "AD", label: "安道尔"},
    { value: "AO", label: "安哥拉"},
    { value: "AI", label: "安圭拉"},
    { value: "AQ", label: "南极洲"},
    { value: "AG", label: "安提瓜和巴布达"},
    { value: "AR", label: "阿根廷"},
    { value: "AM", label: "亚美尼亚"},
    { value: "AW", label: "阿鲁巴"},
    { value: "AU", label: "澳大利亚"},
    { value: "AT", label: "奥地利"},
    { value: "AX", label: "奥兰群岛"},
    { value: "AZ", label: "阿塞拜疆共和国"},
    { value: "BS", label: "巴哈马"},
    { value: "BH", label: "巴林"},
    { value: "BD", label: "孟加拉国"},
    { value: "BB", label: "巴巴多斯"},
    { value: "BY", label: "白俄罗斯"},
    { value: "BE", label: "比利时"},
    { value: "BZ", label: "伯里兹"},
    { value: "BJ", label: "贝宁"},
    { value: "BL", label: "圣巴泰勒米岛"},
    { value: "BM", label: "百慕大"},
    { value: "BT", label: "不丹"},
    { value: "BO", label: "玻利维亚"},
    { value: "BQ", label: "荷兰加勒比区"},
    { value: "BA", label: "波斯尼亚和黑塞哥维那"},
    { value: "BW", label: "博茨瓦纳"},
    { value: "BV", label: "布韦岛"},
    { value: "BR", label: "巴西"},
    { value: "IO", label: "英属印度洋领地"},
    { value: "BN", label: "文莱达鲁萨兰国"},
    { value: "BG", label: "保加利亚"},
    { value: "BF", label: "布基纳法索"},
    { value: "BI", label: "布隆迪"},
    { value: "KH", label: "柬埔寨"},
    { value: "CM", label: "喀麦隆"},
    { value: "CA", label: "加拿大"},
    { value: "CV", label: "佛得角"},
    { value: "KY", label: "开曼群岛"},
    { value: "CF", label: "中非共和国"},
    { value: "TD", label: "乍得"},
    { value: "CL", label: "智利"},
    { value: "CX", label: "圣诞岛"},
    { value: "CC", label: "科科斯群岛"},
    { value: "CD", label: "刚果（金）"},
    { value: "CO", label: "哥伦比亚"},
    { value: "KM", label: "科摩罗"},
    { value: "CG", label: "刚果（布）"},
    { value: "CK", label: "库克群岛"},
    { value: "CR", label: "科特迪瓦"},
    { value: "CI", label: "克罗地亚"},
    { value: "HR", label: "古巴共和国"},
    { value: "CU", label: "塞浦路斯"},
    { value: "CY", label: "捷克共和国"},
    { value: "CZ", label: "捷克"},
    { value: "DK", label: "丹麦"},
    { value: "DJ", label: "吉布提"},
    { value: "DM", label: "多米尼克"},
    { value: "DO", label: "多米尼加共和国"},
    { value: "TL", label: "东帝汶"},
    { value: "EC", label: "厄瓜多尔"},
    { value: "EG", label: "埃及"},
    { value: "SV", label: "萨尔瓦多"},
    { value: "GQ", label: "赤道几内亚"},
    { value: "ER", label: "厄立特里亚"},
    { value: "EE", label: "爱沙尼亚"},
    { value: "ET", label: "埃塞俄比亚"},
    { value: "FO", label: "法罗群岛"},
    { value: "FK", label: "福克兰群岛"},
    { value: "FJ", label: "斐济"},
    { value: "FI", label: "芬兰"},
    { value: "FR", label: "法国"},
    { value: "PF", label: "法属玻力尼西亚"},
    { value: "TF", label: "法属南部领地"},
    { value: "GA", label: "加蓬"},
    { value: "GM", label: "冈比亚"},
    { value: "GE", label: "格鲁吉亚"},
    { value: "DE", label: "德国"},
    { value: "GG", label: "根西岛"},
    { value: "GH", label: "加纳"},
    { value: "GI", label: "直布罗陀"},
    { value: "GR", label: "希腊"},
    { value: "GL", label: "格陵兰"},
    { value: "GD", label: "格林纳达"},
    { value: "GP", label: "瓜德罗普"},
    { value: "GU", label: "关岛"},
    { value: "GT", label: "危地马拉"},
    { value: "GF", label: "法属圭亚那"},
    { value: "GN", label: "几内亚"},
    { value: "GW", label: "几内亚比绍"},
    { value: "GY", label: "圭亚那"},
    { value: "HT", label: "海地"},
    { value: "HM", label: "赫德岛和麦克唐纳群岛"},
    { value: "HN", label: "洪都拉斯"},
    { value: "HU", label: "匈牙利"},
    { value: "IS", label: "冰岛"},
    { value: "IM", label: "马恩岛"},
    { value: "IN", label: "印度"},
    { value: "ID", label: "印度尼西亚"},
    { value: "IR", label: "伊朗"},
    { value: "IQ", label: "伊拉克"},
    { value: "IE", label: "爱尔兰"},
    { value: "IL", label: "以色列"},
    { value: "IT", label: "意大利"},
    { value: "JE", label: "泽西岛"},
    { value: "JM", label: "牙买加"},
    { value: "JP", label: "日本"},
    { value: "JO", label: "约旦"},
    { value: "KZ", label: "哈萨克斯坦"},
    { value: "KE", label: "肯尼亚"},
    { value: "KI", label: "基里巴斯"},
    { value: "KR", label: "韩国"},
    { value: "KW", label: "科威特"},
    { value: "KG", label: "吉尔吉斯共和国"},
    { value: "LA", label: "老挝"},
    { value: "LV", label: "拉脱维亚"},
    { value: "LB", label: "黎巴嫩"},
    { value: "LS", label: "莱索托"},
    { value: "LR", label: "利比里亚"},
    { value: "LY", label: "利比亚"},
    { value: "LI", label: "列支敦士登"},
    { value: "LT", label: "立陶宛"},
    { value: "LU", label: "卢森堡"},
    { value: "MK", label: "马其顿"},
    { value: "MG", label: "马达加斯加"},
    { value: "MW", label: "马拉维"},
    { value: "MY", label: "马来西亚"},
    { value: "MV", label: "马尔代夫"},
    { value: "ML", label: "马里"},
    { value: "MT", label: "马耳他"},
    { value: "MH", label: "马绍尔群岛"},
    { value: "MQ", label: "马提尼克"},
    { value: "MR", label: "毛里塔尼亚"},
    { value: "MU", label: "毛里求斯"},
    { value: "YT", label: "马约特"},
    { value: "MX", label: "墨西哥"},
    { value: "FM", label: "密克罗尼西亚联邦"},
    { value: "MD", label: "摩尔多瓦"},
    { value: "MC", label: "摩纳哥"},
    { value: "ME", label: "黑山"},
    { value: "MF", label: "法属圣马丁"},
    { value: "MN", label: "蒙古"},
    { value: "MS", label: "蒙塞拉特岛"},
    { value: "MA", label: "摩洛哥"},
    { value: "MZ", label: "莫桑比克"},
    { value: "MM", label: "缅甸"},
    { value: "NA", label: "纳米比亚"},
    { value: "NR", label: "瑙鲁"},
    { value: "NP", label: "尼泊尔"},
    { value: "NL", label: "荷兰"},
    { value: "NC", label: "新喀里多尼亚"},
    { value: "NZ", label: "新西兰"},
    { value: "NI", label: "尼加拉瓜"},
    { value: "NE", label: "尼日尔"},
    { value: "NG", label: "尼日利亚"},
    { value: "NU", label: "纽埃"},
    { value: "NF", label: "诺福克岛"},
    { value: "KP", label: "朝鲜民主主义共和国"},
    { value: "MP", label: "北马里亚纳群岛"},
    { value: "NO", label: "挪威"},
    { value: "OM", label: "阿曼"},
    { value: "PK", label: "巴基斯坦"},
    { value: "PW", label: "帕劳"},
    { value: "PA", label: "巴拿马"},
    { value: "PG", label: "巴布亚新几内亚"},
    { value: "PY", label: "巴拉圭"},
    { value: "PE", label: "秘鲁"},
    { value: "PH", label: "菲律宾"},
    { value: "PN", label: "皮特凯恩群岛"},
    { value: "PL", label: "波兰"},
    { value: "PS", label: "巴勒斯坦"},
    { value: "PT", label: "葡萄牙"},
    { value: "PR", label: "波多黎各"},
    { value: "QA", label: "卡塔尔"},
    { value: "RE", label: "留尼汪"},
    { value: "RO", label: "罗马尼亚"},
    { value: "RS", label: "塞尔维亚"},
    { value: "RU", label: "俄罗斯"},
    { value: "RW", label: "卢旺达"},
    { value: "LC", label: "圣卢西亚"},
    { value: "SM", label: "圣马力诺"},
    { value: "SS", label: "南苏丹"},
    { value: "ST", label: "圣多美和普林西比"},
    { value: "SA", label: "沙特阿拉伯"},
    { value: "SN", label: "塞内加尔"},
    { value: "SC", label: "塞舌尔"},
    { value: "SL", label: "塞拉利昂"},
    { value: "SG", label: "新加坡"},
    { value: "SK", label: "斯洛伐克共和国"},
    { value: "SI", label: "斯洛文尼亚"},
    { value: "SB", label: "所罗门群岛"},
    { value: "SO", label: "索马里"},
    { value: "ZA", label: "南非"},
    { value: "GS", label: "南乔治亚岛和南桑威奇群岛"},
    { value: "ES", label: "西班牙"},
    { value: "LK", label: "斯里兰卡"},
    { value: "SH", label: "圣赫勒拿"},
    { value: "KN", label: "圣基茨和尼维斯"},
    { value: "PM", label: "圣皮埃尔和密克隆"},
    { value: "VC", label: "圣文森特和格林纳丁斯"},
    { value: "SD", label: "苏丹"},
    { value: "SR", label: "苏里南"},
    { value: "SJ", label: "斯瓦尔巴群岛和扬马延岛"},
    { value: "SZ", label: "斯威士兰"},
    { value: "SE", label: "瑞典"},
    { value: "CH", label: "瑞士"},
    { value: "SY", label: "叙利亚"},
    { value: "TJ", label: "塔吉克斯坦"},
    { value: "TZ", label: "坦桑尼亚"},
    { value: "TH", label: "泰国"},
    { value: "TG", label: "多哥"},
    { value: "TK", label: "托克劳"},
    { value: "TO", label: "汤加"},
    { value: "TT", label: "特立尼达和多巴哥"},
    { value: "TN", label: "突尼斯"},
    { value: "TR", label: "土耳其"},
    { value: "TM", label: "土库曼斯坦"},
    { value: "TC", label: "特克斯和凯科斯群岛"},
    { value: "TV", label: "图瓦卢"},
    { value: "UM", label: "美国本土外小岛屿"},
    { value: "UG", label: "乌干达"},
    { value: "UA", label: "乌克兰"},
    { value: "AE", label: "阿联酋"},
    { value: "GB", label: "英国"},
    { value: "UY", label: "乌拉圭"},
    { value: "UZ", label: "乌兹别克斯坦"},
    { value: "VU", label: "瓦努阿图"},
    { value: "VA", label: "梵蒂冈"},
    { value: "VE", label: "委内瑞拉"},
    { value: "VN", label: "越南"},
    { value: "VG", label: "英属维尔京群岛"},
    { value: "VI", label: "美属维尔京群岛"},
    { value: "WF", label: "瓦利斯和富图纳群岛"},
    { value: "EH", label: "西撒哈拉"},
    { value: "WS", label: "萨摩亚"},
    { value: "YE", label: "也门民主人民共和国"},
    { value: "ZM", label: "赞比亚"},
    { value: "ZW", label: "津巴布韦"}
]
