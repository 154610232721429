<template>
  <div class="page-b2b-order-detail">
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
    <template v-else>
      <div class="mod-group">
        <div class="cell-header">
          <h4>订单基础信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ data.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ data.submitAccount }}</td>
              <th>联系电话:</th>
              <td>{{ data.contact.contactPhone }}</td>
            </tr>
            <tr>
              <th>订单编号:</th>
              <td>{{ data.orderNo }}</td>
              <th>提交时间:</th>
              <td>{{ data.createTime }}</td>
              <th>成交时间:</th>
              <td>{{ data.dealTime }}</td>
            </tr>
            <tr>
              <th>订单状态:</th>
              <td>{{ data.status | status }}</td>
              <th>有无名单:</th>
              <td>{{ data.hasPassenger ? '有' : '无' }}</td>
              <th>拆分类别:</th>
              <td>{{ (data.orderId === data.parentOrderId || !data.parentOrderId) ? '主订单' : '子订单' }}</td>
            </tr>
            <tr>
              <th>订单类型:</th>
              <td>{{ data.orderType | orderType }}</td>
              <th>订单备注:</th>
              <td colspan="3">{{ data.desc }}</td>
            </tr>
          </table>
        </div>
      </div>

      <detail-segment v-if="data.segments && data.segments.length" :list="data.segments"></detail-segment>

      <div class="mod-group">
        <div class="cell-header">
          <h4>价格信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>成交单价:</th>
              <td>{{ data.statisticInfo.totalUnitNetFare }}</td>
              <th>成交数量:</th>
              <td>{{ data.leftGroupQty }} (<span style="color: red;">初始成交数量: {{ data.intGroupQty }}</span>)</td>
              <th>PNR:</th>
              <td>
                <span class="pnr-href" v-for="pnr in data.pnrs" :key="pnr.id" @click="onPnr(pnr)" style="margin-right: 4px; cursor: pointer;">{{ pnr.pnr }}</span>
              </td>
            </tr>
            <tr>
              <th>总票款:</th>
              <td>{{ data.statisticInfo.totalNetFare }}</td>
              <th>应付金额:</th>
              <td>{{ data.statisticInfo.totalOrderAmount }}</td>
              <th>最低成团人数:</th>
              <td>{{ data.minGroupQty }}</td>
            </tr>
            <tr>
              <th>机场建设费:</th>
              <td>{{ data.statisticInfo.totalAirportTax }}</td>
              <th>燃油附加费:</th>
              <td>{{ data.statisticInfo.totalFuelTax }}</td>
              <th>团队折扣率:</th>
              <td>{{ data.teamDiscount !== 'undefined' ? `${(data.teamDiscount * 100).toFixed(0)}%` : '' }}</td>
            </tr>
            <tr :style="{'vertical-align': 'top'}">
              <th >押金期限:</th>
              <td>
                <div v-for="(depositLimit) in data.uniDepositPayDTOS" :key="depositLimit.id">
                  期限: {{ depositLimit.limit | format('YYYY-MM-DD HH:mm') }} 支付: {{ depositLimit.payRate | int }}
                </div>
              </td>
              <th>出票期限:</th>
              <td>{{ data.timeLimit | format('YYYY-MM-DD HH:mm') }}</td>
              <th>出票率要求:</th>
              <td>{{ data.ticketRate | int }}</td>
            </tr>
          </table>
        </div>
      </div>
      <detail-sub-order title="子订单信息" v-if="data.subOrders && data.subOrders.length" :list="data.subOrders"></detail-sub-order>
      <detail-sub-order title="主订单信息" v-if="data.parentOrder" :list="[data.parentOrder]"></detail-sub-order>
      <div class="mod-group mod-reference" v-if="data.referenceOrderDetails && data.referenceOrderDetails.length">
        <div class="cell-header">
          <h4>参考订单需求</h4>
        </div>
        <div class="cell-body">
          <detail-reference :list="data.referenceOrderDetails"></detail-reference>
          <table class="table-action" style="margin-top: 20px;">
            <tr>
              <th>修改订单:</th>
              <td style="text-align: center;">
                <a-button type="primary" style="margin-right: 20px;" @click="onModifyPrice">修改需求价格</a-button>
                <a-button type="primary" @click="onModifyCnt">修改需求量</a-button>
              </td>
            </tr>
            <tr>
              <th>销售规则:</th>
              <td style="text-align: center;">
                <span style="margin-right: 50px;">押金期限: </span>
                <span>出票时限: </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <detail-payment v-if="data.payments && data.payments.length" :list="data.payments">
        <div style="margin-top: 16px;">
          <span>已支付金额: {{ data.statisticInfo.totalPaidAmount }}</span>
          <span style="margin-left: 100px; color: #f00;">尚需支付金额: {{ data.statisticInfo.unPaidAmount }}</span>
        </div>
      </detail-payment>

      <detail-operation v-if="data.sysLogDTOS && data.sysLogDTOS.length" :list="data.sysLogDTOS"></detail-operation>

      <!-- <admin-flow-table v-if="audits && audits.length" :list="audits"></admin-flow-table> -->

      <div class="mod-action">
        <div>
          <a-button type="link" @click="onAdd">订单加人申请</a-button>
          <a-button type="link" @click="onRemove">订单减人</a-button>
          <a-button type="link" @click="onModifyPassenger">旅客信息</a-button>
          <a-button type="link" @click="onCheckTicket">查看票号</a-button>
          <a-button type="link" @click="onRefund">退票</a-button>
          <a-button type="link" @click="onAllocate">分离出票</a-button>
        </div>
        <div>
          <a-button type="link" @click="onPayDeposit">支付押金</a-button>
          <a-button type="link" @click="onPay">支付票款</a-button>
          <a-button type="link" @click="onRefundDeposit">退押金</a-button>
          <a-button type="link" @click="onCheckout">出票（同步PNR名单）</a-button>
          <a-button type="link" @click="onMidifySimilar">修改同音字</a-button>
          <a-button type="link" @click="onModifyCertNo">修改证件号</a-button>
        </div>
        <div>
          <a-button type="link" @click="onMisPay">申请差错退款</a-button>
          <a-button type="link" v-if="true" @click="onInsuranceBuy">购买保险</a-button>
          <a-button type="link" v-else></a-button>
          <a-button type="link"></a-button>
          <a-button type="link"></a-button>
          <a-button type="link"></a-button>
          <a-button type="link"></a-button>
        </div>
      </div>
    </template>

    <a-modal
      :class="['page-b2b-order-detail-model', type]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="modalInfo ? modalInfo.width : 600"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="cell-header" slot="title">
        {{ modalInfo ? modalInfo.title : '' }}
        <span>订单号: {{ data.orderNo }}</span>
        <span>订单状态: {{ data.status | status }}</span>
      </div>
      <div class="cell-body">
        <detail-add-people ref="add" v-if="type === 'add'" :orderNo="data.orderNo" :pnrs="data.pnrs">
          <span>{{ data.pnrs | pnrNum }}</span>
        </detail-add-people>
        <detail-remove-people ref="remove" v-if="type === 'remove'" :orderNo="data.orderNo"></detail-remove-people>
        <detail-passenger ref="modifyPassenger" v-if="type === 'modifyPassenger'" :orderNo="data.orderNo" :status="data.status"></detail-passenger>
        <detail-ticketno ref="ticketNo" v-if="type === 'ticketNo'" :list="data.ticketPnrPassengerInfos"></detail-ticketno>
        <detail-split-ticket ref="splitTicket" v-if="type === 'splitTicket'" :orderNo="data.orderNo" :count="data.leftGroupQty"></detail-split-ticket>
        <detail-modify-certno ref="modifyCertNo" v-if="type === 'modifyCertNo'" :orderNo="data.orderNo"></detail-modify-certno>
        <detail-modify-similar ref="modifySimilar" v-if="type === 'modifySimilar'" :orderNo="data.orderNo"></detail-modify-similar>
        <detail-modify-cnt ref="modifyCnt" v-if="type === 'modifyCnt'" :orderNo="data.orderNo" :current-cnt="data.leftGroupQty"></detail-modify-cnt>
        <detail-deposit-pay ref="depositPay" v-if="type === 'depositPay'" :orderNo="data.orderNo" :status="data.status"></detail-deposit-pay>
        <detail-deposit-refund ref="depositRefund" v-if="type === 'depositRefund'" :order="data"></detail-deposit-refund>
        <detail-ticket-pay ref="ticketPay" v-if="type === 'ticketPay'" :orderNo="data.orderNo" :status="data.status"></detail-ticket-pay>
        <detail-checkout ref="checkout" v-if="type === 'checkout'" :order="data" :status="data.status"
         @success="onCheckoutSuccess" @fail="onCheckoutFail"></detail-checkout>
      </div>
    </a-modal>
  </div>
</template>

<script>
import queryString from 'query-string'
import { fetchOrderDetail, getPnrRT } from '@/api/order'
import DetailSegment from './components/detail-segment'
import DetailSubOrder from './components/detail-suborder'
import DetailPayment from './components/detail-payment'
import DetailOperation from './components/detail-operation'
import DetailAddPeople from './components/detail-add'
import DetailRemovePeople from './components/detail-remove'
import DetailPassenger from './components/detail-passenger'
import DetailSplitTicket from './components/detail-split-ticket'
import DetailTicketno from './components/detail-ticketno'
import DetailModifyCertno from './components/detail-modify-certno'
import DetailModifySimilar from './components/detail-modify-similar'
import DetailReference from './components/detail-reference.vue'
import DetailModifyCnt from './components/detail-modify-cnt.vue'
import DetailDepositPay from './components/detail-deposit-pay.vue'
import DetailDepositRefund from './components/detail-deposit-refund.vue'
import DetailTicketPay from './components/detail-ticket-pay.vue'
import DetailCheckout from './components/detail-checkout.vue'
import { format } from '@/filters/time'
import { status, orderType } from '@/filters/order'
import './css/detail.less'

const modalInfoMap = {
  add: {
    title: '订单加人申请',
    width: 600
  },
  remove: {
    title: '订单减人',
    width: 600
  },
  modifyPassenger: {
    title: '旅客名单',
    width: '80%'
  },
  ticketNo: {
    title: '查看票号',
    width: 600
  },
  modifySimilar: {
    title: '修改同音字',
    width: 600
  },
  modifyCertNo: {
    title: '修改乘客证件号',
    width: 600
  },
  modifyCnt: {
    title: '修改成交量',
    width: 600
  },
  depositPay: {
    title: '支付押金',
    width: 600
  },
  depositRefund: {
    title: '退押金',
    width: 800
  },
  ticketPay: {
    title: '支付票款',
    width: 600
  },
  checkout: {
    title: '出票',
    width: 600
  }
}

export default {
  data () {
    return {
      data: {},
      audits: null,
      visible: false,
      type: '',
      confirmLoading: false,
      loading: false
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.fetchDetail(params.id, params.userId)
  },
  computed: {
    modalInfo () {
      return modalInfoMap[this.type]
    }
  },
  methods: {
    async fetchDetail (id, userId) {
      this.loading = true

      try {
        const data = await fetchOrderDetail({
          orderNo: id,
          userId
        })

        this.data = data.bizData
        this.audits = data.auditHis
        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    async handleOk () {
      const { type } = this

      try {
        this.confirmLoading = true
        await this.$refs[type].submit()
        this.confirmLoading = false
      } catch (err) {
        this.confirmLoading = false
      }
    },
    handleCancel () {
      this.visible = false
      this.type = ''
    },
    onAdd () {
      this.type = 'add'
      this.visible = true
    },
    onRemove () {
      this.type = 'remove'
      this.visible = true
    },
    onRefund () {
      this.$router.push({ path: '/orderManager/orderRefund', query: { orderNo: this.data.orderNo } })
    },
    onAllocate () {
      this.type = 'splitTicket'
      this.visible = true
    },
    onPayDeposit () {
      this.type = 'depositPay'
      this.visible = true
    },
    onPay () {
      this.type = 'ticketPay'
      this.visible = true
    },
    onRefundDeposit () {
      this.type = 'depositRefund'
      this.visible = true
    },
    onCheckout () {
      this.type = 'checkout'
      this.visible = true
    },
    onModifyPassenger () {
      this.type = 'modifyPassenger'
      this.visible = true
    },
    onCheckTicket () {
      this.type = 'ticketNo'
      this.visible = true
    },
    onModifyCertNo () {
      this.type = 'modifyCertNo'
      this.visible = true
    },
    onMidifySimilar () {
      this.type = 'modifySimilar'
      this.visible = true
    },
    onModifyCnt () {
      this.type = 'modifyCnt'
      this.visible = true
    },
    onMisPay () {
      this.$router.push({ path: '/orderManager/differRefundApply', query: { orderNo: this.data.orderNo } })
    },
    onInsuranceBuy () {
      if (this.data.status !== 'PF') {
        this.$message.warning({
          content: '未出票订单不能购买保险。',
          centered: true,
          okText: '确认'
        })
        return
      }
      this.$router.push({ path: '/insurance/book', query: { orderNo: this.data.orderNo } })
    },
    onCheckoutSuccess () {
      this.$success({
        content: '系统正在出票，请您耐心等待几分钟。',
        centered: true,
        okText: '确认',
        onOk: async () => {
          location.reload()
        }
      })
    },
    onCheckoutFail () {

    },
    async onPnr (pnr) {
      try {
        const data = await getPnrRT({
          airlineCode: this.$airSetttings.airline,
          pnrNo: pnr.pnr
        })

        if (data.oringinalRT) {
          this.$info({
            width: 850,
            title: 'PNR信息',
            class: 'ticket-modal',
            content: (
              <div class="ticket-green-box" domPropsInnerHTML={ data.oringinalRT.replace(/\r\n/g, '<br/>').replace(/\s{1}/g, '&nbsp;&nbsp;') }></div>
            )
          })
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    // 审批
    async onSubmit () {
      try {
        this.confirmLoading = true
        await this.$refs.flowAction.submit(this.data)

        this.$message.success('审核成功，即将刷新页面', () => {
          location.reload()
        })
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    }
  },
  filters: {
    format,
    status,
    orderType,
    pnr (pnrs = []) {
      if (!pnrs.length) {
        return
      }

      return pnrs.map(item => item.pnr).join('、')
    },
    pnrNum (pnrs = []) {
      if (!pnrs.length) {
        return 0
      }

      return pnrs.map(item => {
        return `${item.pnr}【${item.cnt}人】`
      }).join('、')
    },
    int (value) {
      return parseInt(value * 100, 0) + '%'
    }
  },
  components: {
    DetailSegment,
    DetailSubOrder,
    DetailPayment,
    DetailOperation,
    DetailAddPeople,
    DetailRemovePeople,
    DetailPassenger,
    DetailSplitTicket,
    DetailTicketno,
    DetailModifyCertno,
    DetailModifySimilar,
    DetailReference,
    DetailModifyCnt,
    DetailDepositPay,
    DetailDepositRefund,
    DetailTicketPay,
    DetailCheckout
  }
}
</script>
