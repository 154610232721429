<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>{{ title }}</h4>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orgDst" slot-scope="text, record">
          <div v-for="segment in record.segments" :key="segment.id">
            {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}
          </div>
        </span>
        <span slot="flightNo" slot-scope="text, record">
          <div v-for="segment in record.segments" :key="segment.id">
            {{ segment.flightNo }}
          </div>
        </span>
        <span slot="flightDate" slot-scope="text, record">
          <div v-for="segment in record.segments" :key="segment.id">
            {{ segment.flightDate | format }}
          </div>
        </span>
        <span slot="pnr" slot-scope="text">
          <div v-for="pnr in text" :key="pnr.id" style="margin-right: 4px;">{{ pnr.pnr }}</div>
        </span>
        <span slot="totalUnitNetFare" slot-scope="totalUnitNetFare, row">
          {{ row.statisticInfo.totalUnitNetFare  }}
        </span>
        <span slot="status" slot-scope="text">
          {{ text | status }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { status } from '@/filters/order'
import { airCity } from '@/filters/flight'
import { format } from '@/filters/time'

export default {
  props: {
    list: Array,
    title: String
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          width: 64,
          align: 'center'
        },
        {
          title: '订单号',
          dataIndex: 'orderNo'
        },
        {
          title: '航段',
          dataIndex: 'segments',
          key: 'orgDst',
          scopedSlots: { customRender: 'orgDst' }
        },
        {
          title: '航班号',
          dataIndex: 'segments',
          key: 'flightNo',
          scopedSlots: { customRender: 'flightNo' }
        },
        {
          title: '航班日期',
          dataIndex: 'segments',
          key: 'flightDate',
          scopedSlots: { customRender: 'flightDate' }
        },
        {
          title: '人数',
          dataIndex: 'leftGroupQty'
        },
        {
          title: '价格',
          dataIndex: 'totalUnitNetFare',
          scopedSlots: { customRender: 'totalUnitNetFare' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: 'PNR编码',
          dataIndex: 'pnrs',
          scopedSlots: { customRender: 'pnr' }
        },
        {
          title: '订单生成日期',
          dataIndex: 'createTime'
        }
      ]
    }
  },
  filters: {
    airCity,
    status,
    format
  }
}
</script>
