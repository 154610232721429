<template>
  <div>
    <a-alert class="detail-dialog-notice-banner" type="warning" message="此订单没有旅客名单，请输入新的订单需求人数" banner />
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
      <a-form-item label="请选择PNR编码">
        <a-select :options="pnrs" v-decorator="['pnr', { rules: [{ required: true, message: '请选择PNR' }] }]" @change="onChange"></a-select>
      </a-form-item>
      <a-form-item label="PNR人数调整为">
        <a-input type="number" v-decorator="['cnt', { rules: [{ required: true, message: '请输入修改数量' }] }]"></a-input>
        <span>【输入PNR中新的人数】</span>
      </a-form-item>
      <!-- <a-form-item label="减人原因">
        <a-select :options="reasonOptions" v-decorator="['reason', { rules: [{ required: true, message: '请选择减人原因' }] }]"></a-select>
      </a-form-item> -->
      <a-form-item label="备注">
        <a-textarea v-decorator="['mark']"></a-textarea>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { find } from 'lodash'
import { fetchinVentoryDetail, removePeople } from '@/api/order'

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'remove-people' }),
      pnrs: [],
      pnr: null,
      reasonOptions: [
        {
          label: '自愿退座',
          value: 'PERSONAL_REASON'
        },
        {
          label: '航空公司调整价格',
          value: 'FARE_ADJUSTMENT'
        },
        {
          label: '航空公司征位',
          value: 'AIRLINE_OCCUPY'
        },
        {
          label: '航班不正常',
          value: 'IRREGULAR_FLIGHT'
        }
      ]
    }
  },
  props: {
    orderNo: String
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      const { orderNo } = this

      try {
        const { pnrDetails = [] } = await fetchinVentoryDetail({
          orderNo
        })

        if (!pnrDetails) {
          return
        }

        this.pnrs = pnrDetails.map(item => {
          return {
            value: item.id,
            label: item.pnr,
            cnt: item.cnt
          }
        })
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onChange (value) {
      const { pnrs } = this

      const pnr = this.pnr = find(pnrs, { value })

      if (pnr) {
        this.form.setFieldsValue({
          cnt: pnr.cnt
        })
      }
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            const { orderNo, pnr } = this

            try {
              this.data = await removePeople({
                orderNo,
                id: pnr.value,
                pnr: pnr.label,
                cnt: values.cnt,
                flag: false,
                mark: values.mark,
                reason: values.reason
              })
              this.$message.success('订单减人成功，即将刷新页面', function () {
                location.reload()
              })
            } catch (err) {
              this.loading = false
              this.$message.error(err.message)
              reject(new Error())
            }
          } else {
            reject(new Error())
          }
        })
      })
    }
  }
}
</script>
