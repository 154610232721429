<template>
  <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
    <a-form-item label="当前需求数量">
      <span>{{ currentCnt }}</span>
    </a-form-item>
    <a-form-item label="调整需求梳理">
      <a-input type="number" v-decorator="['cnt', { rules: [{ required: true, message: '请输入数量' }] }]"></a-input>
    </a-form-item>
    <a-form-item label="备注">
      <a-textarea v-decorator="['mark']"></a-textarea>
    </a-form-item>
  </a-form>
</template>

<script>
import { modifyCnt } from '@/api/order'

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'modify-cnt' })
    }
  },
  props: {
    currentCnt: Number,
    orderNo: String
  },
  methods: {
    submit () {
      return new Promise((resolve, reject) => {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            const { orderNo } = this

            try {
              this.data = await modifyCnt({
                orderNo,
                ...values
              })
              this.$message.success('修改需求数量成功，即将刷新页面', function () {
                location.reload()
              })
            } catch (err) {
              this.loading = false
              this.$message.error(err.message)
              reject(new Error())
            }
          } else {
            reject(new Error())
          }
        })
      })
    }
  }
}
</script>
