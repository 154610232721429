<template>
  <div>
    <a-alert class="detail-dialog-notice-banner" v-if="!pnrs.length" type="warning" message="此订单没有旅客名单，请输入新的订单需求人数" banner />
    <div class="detail-dialog-header">
      <a-table
          class="add-record"
          size="small"
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
          bordered
        >
      </a-table>
      <span>当前订单人数：{{ pnrs | pnrNum }}</span>
      </div>
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 10 }">
      <a-form-item label="增加的人数">
        <a-input-number :min='1' :max='30' v-decorator="['cnt', { rules: cntRule}]"></a-input-number>
        <span>【不超过30人】</span>
      </a-form-item>
      <a-form-item label="加人原因">
        <a-textarea v-decorator="['mark']"></a-textarea>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { addPeople, fetchinVentoryDetail } from '@/api/order'

const columns = [
  {
    title: '申请人数',
    dataIndex: 'rqCnt'
  },
  {
    title: '申请状态',
    dataIndex: 'statusName'
  }
]

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'add-people' }),
      auditInventoryDetails: [],
      columns,
      tableData: [],
      cntRule: [
        { required: true, message: '请输入增加人数' },
        {
          type: 'integer',
          message: '请输入整数'
        }
      ]
    }
  },
  props: {
    orderNo: String,
    pnrs: Array
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      const { orderNo } = this

      try {
        const { auditInventoryDetails = [] } = await fetchinVentoryDetail({
          orderNo
        })

        if (!auditInventoryDetails) {
          return
        }

        this.tableData = auditInventoryDetails
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            const { orderNo } = this

            try {
              this.data = await addPeople({
                audit: {
                  action: 'START'
                },
                bizDataJson: JSON.stringify({
                  orderNo,
                  ...values
                })
              })
              this.$message.success('订单加人申请已提交，即将刷新页面', function () {
                location.reload()
              })
            } catch (err) {
              this.loading = false
              this.$message.error(err.message)
              reject(new Error())
            }
          } else {
            reject(new Error())
          }
        })
      })
    }
  },
  filters: {
    pnrNum (pnrs = []) {
      if (!pnrs.length) {
        return 0
      }

      return pnrs.map(item => {
        return `${item.pnr}【${item.cnt}人】`
      }).join('、')
    }
  }
}
</script>

<style lang="less" scoped>
.add-record {
  margin-bottom: 15px;
}
</style>
