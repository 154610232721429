<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>支付信息</h4>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="payType" slot-scope="text">
          {{ text | bank }}
        </span>
        <span slot="payTime" slot-scope="text">
          {{ text | formatTime('YYYY-MM-DD HH:mm:ss') }}
        </span>
        <span slot="payStatus" slot-scope="text">
          {{ text | payStatus }}
        </span>
      </a-table>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { format } from '@/filters/time'
import { bank, status as payStatus } from '@/filters/pay'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '支付流水号',
          dataIndex: 'tradeNo'
        },
        {
          title: '支付金额',
          dataIndex: 'amount'
        },
        {
          title: '支付时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          scopedSlots: { customRender: 'payType' }
        },
        {
          title: '第三方平台交易号',
          dataIndex: 'dealId'
        },
        {
          title: '状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' }
        },
        {
          title: '备注',
          dataIndex: 'payDesc'
        }
      ]
    }
  },
  props: {
    list: Array
  },
  filters: {
    bank,
    payStatus,
    formatTime: format
  }
}
</script>
