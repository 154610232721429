<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>航班信息</h4>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orgDst" slot-scope="text, record">
          {{ text | airCity }}-{{ record.arrCode | airCity }}
        </span>
        <span slot="discount" slot-scope="text">
          {{ text ? `${text}%` : '-' }}
        </span>
        <span slot="depTime" slot-scope="text">
          {{ text | format('YYYY-MM-DD HH:mm') }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { airCity } from '@/filters/flight'
import { format } from '@/filters/time'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '航段',
          dataIndex: 'depCode',
          scopedSlots: { customRender: 'orgDst' }
        },
        {
          title: '航班日期',
          dataIndex: 'depTime',
          scopedSlots: { customRender: 'depTime' }
        },
        {
          title: '航班号',
          dataIndex: 'flightNo'
        },
        {
          title: '舱位',
          dataIndex: 'cabin'
        },
        {
          title: '人数',
          dataIndex: 'passengerCount',
          key: 'people'
        },
        {
          title: '票价',
          dataIndex: 'netFare'
        },
        {
          title: '折扣',
          dataIndex: 'discount',
          scopedSlots: { customRender: 'discount' }
        },
        {
          title: '机建费',
          dataIndex: 'airportTax'
        },
        {
          title: '燃油费',
          dataIndex: 'fuelTax'
        }
      ]
    }
  },
  props: {
    list: Array
  },
  filters: {
    airCity,
    format
  }
}
</script>
