<template>
  <div class="detail-passenger-container cell-inner">
    <div class="inner-body">
      <a-spin :spinning="loading">
        <div class="passenger-header clearfix">
          <span>备注：儿童类型，姓名中无需添加CHD</span>
          <a-upload v-if="!data.upload" accept=".xls,.xlsx" class="passenger-import" name="file"
            :action="`/api/order/passenger/upload/${orderNo}`" :headers="{ token }" :data="{ orderNo: orderNo }"
            :fileList="fileList" @change="handleUpload">
            <a-button><a-icon type="upload" />导入旅客名单</a-button>
          </a-upload>
        </div>
        <a-form class="b2b-detail-modify-price" :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
          <a-table :rowKey="
            (record, index) => {
              return index;
            }" :data-source="data.passengerDetails" :columns="columns" :pagination="false" bordered :scroll="{
    x: true
  }">
            <a-form-item slot="index" slot-scope="text, record, index">
              {{ index + 1 }}
              <a-input hidden v-decorator="[
                `passengers.[${index}].id`,
                { initialValue: record.id },
              ]"></a-input>
            </a-form-item>
            <a-form-item slot="passengerType" slot-scope="text, record, index">
              <a-select :style="{ width: '100px' }" :options="passengerTypes" v-decorator="[
                `passengers.[${index}].passengerType`,
                { initialValue: text },
              ]"></a-select>
            </a-form-item>
            <a-form-item slot="passengerName" slot-scope="text, record, index">
              <a-input v-decorator="[
                `passengers.[${index}].passengerName`,
                { initialValue: text },
              ]"></a-input>
            </a-form-item>
            <a-form-item slot="certType" slot-scope="text, record, index">
              <a-select :style="{ width: '180px' }" :options="certTypes" v-decorator="[
                `passengers.[${index}].certType`,
                { initialValue: text },
              ]"></a-select>
            </a-form-item>
            <a-form-item slot="certNo" slot-scope="text, record, index">
              <a-input v-decorator="[
                `passengers.[${index}].certNo`,
                { initialValue: text },
              ]"></a-input>
            </a-form-item>
            <a-form-item slot="mobile" slot-scope="text, record, index">
              <a-input v-decorator="[
                `passengers.[${index}].phone`,
                { initialValue: text },
              ]"></a-input>
            </a-form-item>
            <a-form-item slot="nationality" slot-scope="text, record, index">
              <a-select :options="nationalOptions"
                v-decorator="[`passengers.[${index}].nationality`, { initialValue: text || 'CN' }]"></a-select>
            </a-form-item>
            <a-form-item slot="birthDate" slot-scope="text, record, index">
              <a-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD"
                v-decorator="[`passengers.[${index}].birthDate`, { initialValue: text, rules: [{ required: true, message: '请选择出生日期' }] }]" />
            </a-form-item>
            <a-form-item slot="issueCountry" slot-scope="text, record, index">
              <a-select :options="nationalOptions"
                v-decorator="[`passengers.[${index}].issueCountry`, { initialValue: text || 'CN' }]"></a-select>
            </a-form-item>
            <a-form-item slot="certExpried" slot-scope="text, record, index">
              <a-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD"
                v-decorator="[`passengers.[${index}].certExpried`, { initialValue: text, rules: [{ required: true, message: '请选择证件有效期' }] }]" />
            </a-form-item>
            <a-form-item slot="lastName" slot-scope="text, record, index">
              <a-input v-decorator="[`passengers.[${index}].lastName`, { initialValue: text }]"></a-input>
            </a-form-item>
            <a-form-item slot="firstName" slot-scope="text, record, index">
              <a-input v-decorator="[`passengers.[${index}].firstName`, { initialValue: text }]"></a-input>
            </a-form-item>
            <a-form-item slot="gender" slot-scope="text, record, index">
              <a-select :options="genderOptions"
                v-decorator="[`passengers.[${index}].gender`, { initialValue: text }]"></a-select>
            </a-form-item>
            <a-form-item slot="actions" slot-scope="text, record" :style="{ width: '120px' }">
              <template v-if="record.newRow">
                <a-button class="passenger-action-btn" size="small" type="primary" @click="onSave(record)">保存</a-button>
              </template>
              <template v-else>
                <a-button class="passenger-action-btn" :disabled="disableFresh" size="small" type="primary"
                  @click="onModify(record)">更新</a-button>
                <a-button class="passenger-action-btn" :disabled="disableFresh" size="small" type="primary"
                  @click="onDelete(record)">删除</a-button>
              </template>

            </a-form-item>
          </a-table>
          <div class="passenger-notice">
            旅客名单剩余修改个数： <span class="orange">{{ data.leftModifyCnt }}</span>个
          </div>
          <div class="passenger-notice">
            旅客名单模板：<span class="orange"> <a href="/travel-list.xlsx?version=20230516" target="_blank"
                rel="noopener noreferrer">旅客名单模板.xlsx</a></span>
          </div>
          <div class="passenger-notice">
            说明：名单只允许导入一次，<strong>“提交”</strong>旅客名单后，名单只能单个<strong>更新</strong>更新或<strong>删除</strong>
            ；不能再点击<strong>提交</strong>按钮<br />
            每个团最多允许修改3个名单（包含分离出去的子订单，主订单+子订单共3次）
          </div>
        </a-form>
      </a-spin>
    </div>
    <div class="inner-footer">
      <a-button :loading="confirmLoading" :disabled="data.upload" type="primary" style="margin-right: 20px"
        @click="onMultiModify">提交</a-button>
    </div>
  </div>
</template>

<script>
import {
  fetchPassengerDetail,
  addPassengerDetail,
  modifyPassengerDetail,
  deletePassengerDetail,
  submitPassengerDetail
} from '@/api/order'
import { find } from 'lodash'

import moment from 'moment'
import { certType, genderType, nationalType } from '@/dicts/passenger'

export default {
  data () {
    return {
      token: this.$ls.get('Access-Token'),
      passengerTypes: [
        {
          label: '成人',
          value: 'ADT'
        },
        {
          label: '儿童',
          value: 'CHD'
        }
        // {
        //   label: '婴儿',
        //   value: 'INF'
        // }
      ],
      certTypes: certType,
      nationalOptions: nationalType,
      genderOptions: genderType,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 80,
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '旅客类型',
          width: 200,
          dataIndex: 'passengerType',
          scopedSlots: { customRender: 'passengerType' }
        },
        {
          title: '姓名',
          width: 200,
          dataIndex: 'passengerName',
          scopedSlots: { customRender: 'passengerName' }
        },
        {
          title: '证件类型',
          width: 200,
          dataIndex: 'certType',
          scopedSlots: { customRender: 'certType' }
        },
        {
          title: '证件号',
          width: 200,
          dataIndex: 'certNo',
          scopedSlots: { customRender: 'certNo' }
        },
        {
          title: '手机',
          width: 200,
          dataIndex: 'phone',
          scopedSlots: { customRender: 'mobile' }
        },
        {
          title: '国籍',
          width: 200,
          dataIndex: 'nationality',
          scopedSlots: { customRender: 'nationality' }
        },
        {
          title: '出生日期',
          width: 200,
          dataIndex: 'birthDate',
          scopedSlots: { customRender: 'birthDate' }
        },
        {
          title: '签发国',
          width: 200,
          dataIndex: 'issueCountry',
          scopedSlots: { customRender: 'issueCountry' }
        },
        {
          title: '证件有效期',
          width: 200,
          dataIndex: 'certExpried',
          scopedSlots: { customRender: 'certExpried' }
        },
        {
          title: '姓(拼音/英文)',
          width: 200,
          dataIndex: 'lastName',
          scopedSlots: { customRender: 'lastName' }
        },
        {
          title: '名(拼音/英文)',
          width: 200,
          dataIndex: 'firstName',
          scopedSlots: { customRender: 'firstName' }
        },
        {
          title: '性别',
          width: 200,
          dataIndex: 'gender',
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      disableFresh: false,
      form: this.$form.createForm(this, { name: 'modify-passenger' }),
      data: {},
      fileList: [],
      confirmLoading: false,
      loading: false
    }
  },
  props: {
    orderNo: String,
    status: String
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      const { orderNo } = this

      try {
        this.loading = true
        this.data = await fetchPassengerDetail({
          orderNo
        })

        if (this.data.upload) {
          this.disableFresh = false

          const freeRowLength = this.data.leftGroupQty - (this.data.passengerDetails ? this.data.passengerDetails.length : 0)

          if (!this.data.passengerDetails) {
            this.data.passengerDetails = []
          }

          for (let i = 0; i < freeRowLength; i++) {
            this.data.passengerDetails.push({
              passengerType: '',
              passengerName: '',
              certType: '',
              certNo: '',
              mobile: '',
              newRow: true
            })
          }
        }

        if (this.status === 'PF') {
          this.disableFresh = true
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    handleUpload (info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name}上传成功`)
          this.disableFresh = true

          this.data.passengerDetails = info.file.response.data
          this.fileList = info.fileList
        } else {
          this.$message.error(info.file.response.message)
          this.fileList = []
        }
      } else if (info.file.status === 'error') {
        this.fileList = []
        this.$message.error(`${info.file.name}上传失败.`)
      } else {
        this.fileList = info.fileList
      }
    },
    async onSave (row) {
      const { orderNo } = this
      const { passengers } = this.form.getFieldsValue()
      const list = []

      new Array(row).forEach((item) => {
        const passenger = find(passengers, { id: item.id })

        if (passenger) {
          list.push({
            ...item,
            ...passenger,
            ...{
              birthDate: passenger.birthDate ? moment(passenger.birthDate).format('YYYY-MM-DD 00:00:00') : '',
              certExpried: passenger.certExpried ? moment(passenger.certExpried).format('YYYY-MM-DD 00:00:00') : ''
            }
          })
        }
      })

      if (!this.validateList(list)) { return }

      try {
        this.confirmLoading = true
        await addPassengerDetail({
          orderNo,
          passengers: list
        })
        row.newRow = false
        this.confirmLoading = false

        setTimeout(() => {
          this.fetch()
        }, 500)
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    },
    async onModify (row) {
      const { orderNo } = this
      const { passengers } = this.form.getFieldsValue()
      const list = []

      new Array(row).forEach((item) => {
        const passenger = find(passengers, { id: item.id })

        if (passenger) {
          list.push({
            ...item,
            ...passenger,
            ...{
              birthDate: passenger.birthDate ? moment(passenger.birthDate).format('YYYY-MM-DD 00:00:00') : '',
              certExpried: passenger.certExpried ? moment(passenger.certExpried).format('YYYY-MM-DD 00:00:00') : ''
            }
          })
        }
      })

      if (!this.validateList(list)) { return }

      try {
        this.confirmLoading = true
        await modifyPassengerDetail({
          orderNo,
          passengers: list
        })
        this.confirmLoading = false

        setTimeout(() => {
          this.fetch()
        }, 500)
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    },
    async onMultiModify (row) {
      const { orderNo } = this

      const { passengers } = this.form.getFieldsValue()
      const list = []

      if (!passengers) {
        return this.$message.error('没有可以更新旅客')
      }

      passengers.forEach((item) => {
        list.push({
          ...item,
          ...{
            birthDate: item.birthDate ? moment(item.birthDate).format('YYYY-MM-DD 00:00:00') : '',
            certExpried: item.certExpried ? moment(item.certExpried).format('YYYY-MM-DD 00:00:00') : ''
          }
        })
      })

      if (!this.validateList(list)) { return }

      try {
        this.confirmLoading = true
        await submitPassengerDetail({
          orderNo,
          passengers: list
        })
        this.confirmLoading = false

        setTimeout(() => {
          this.fetch()
        }, 500)
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    },
    async onDelete (row) {
      const { orderNo } = this
      try {
        this.confirmLoading = true
        await deletePassengerDetail({
          orderNo,
          passengers: [row]
        })
        this.confirmLoading = false
        setTimeout(() => {
          this.fetch()
        }, 500)
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    },
    validateList (list) {
      for (let i = 0; i < list.length; i++) {
        if (!(['NI', 'HM', 'UU', 'ID22', 'ID25', 'ID26'].indexOf(list[i].certType) > -1)) {
          // 证件类型非NI, HM, UU, ID22, ID25, ID26时, 其他信息必填
          if (!list[i].nationality) {
            this.$message.warning('旅客国籍必填')
            return false
          }
          if (!list[i].birthDate) {
            this.$message.warning('旅客出生日期必填')
            return false
          }
          if (!list[i].issueCountry) {
            this.$message.warning('旅客签发国必填')
            return false
          }
          if (!list[i].certExpried) {
            this.$message.warning('旅客证件有效期必填')
            return false
          }
          if (!list[i].lastName) {
            this.$message.warning('旅客姓(拼音/英文)必填')
            return false
          }
          if (!list[i].firstName) {
            this.$message.warning('旅客名(拼音/英文)必填')
            return false
          }
          if (!list[i].gender) {
            this.$message.warning('旅客性别必填')
            return false
          }
        }
      }

      return true
    }
  },
  filters: {}
}
</script>

<style lang="less" scoped>
.passenger-header {
  line-height: 32px;
  margin-bottom: 15px;

  .passenger-import {
    float: right;
    text-align: right;
  }
}

.passenger-notice {
  .orange {
    color: @joy-orange-color;
  }

  +.passenger-notice {
    margin-top: 10px;
  }
}

.passenger-action-btn {
  +.passenger-action-btn {
    margin-left: 5px;
  }
}

.b2b-detail-modify-price {
  .ant-table {
    margin-bottom: 16px;
  }

  .ant-table-body tr>td {
    padding: 0 16px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  ::v-deep .ant-table-fixed {
    .ant-table-tbody tr>td {
      padding: 0 16px;

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

</style>
