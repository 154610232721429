<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>操作记录</h4>
    </div>
    <div class="cell-body">
      <a-table
        :scroll="{x: 'max-content'}"
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '操作人',
          dataIndex: 'operator'
        },
        {
          title: '操作时间',
          dataIndex: 'updateTime'
        },
        {
          title: '操作类型',
          dataIndex: 'operateName'
        },
        {
          title: '操作信息',
          dataIndex: 'operateContent'
        }
      ]
    }
  },
  props: {
    list: Array
  }
}
</script>
