<template>
  <div class="detail-deposit-pay-container cell-inner">
    <div class="base-info">总票款：<span>￥{{ detail.orderNetFare || '-' }}</span></div>
    <a-table
      rowKey="id"
      :loading="loading"
      :data-source="list"
      :columns="columns"
      :pagination="false"
      bordered
    >
      <span slot="id" slot-scope="row">
        <a-radio
          v-if="row.status === 'PEND'"
          :value="row.id"
          :checked="row.id === checkPay.id"
          @click="onCheckPay(row)"
        ></a-radio>
      </span>
      <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="status">
        {{ status | payStatus }}
      </span>
    </a-table>
    <a-tabs class="deposit-tabs" default-active-key="1">
      <a-tab-pane key="1" tab="第三方支付">
        <div class="deposit-pay-list">
          <div
            class="deposit-pay"
            v-for="method in payMethods"
            :key="method.id"
            :class="{ active: checkPayMethod.id === method.id }"
            @click="onMethodClick(method)"
          >
            {{ method.title }}
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div :style="{color: 'red', marginBottom: '10px'}">
      操作提醒：<br/>
      在快钱页面支付成功后，请点击按钮 “返回商家” 以确保团队网系统及时接收快钱支付通知，请勿直接关闭快钱页面，直接关闭快钱页面将导致订单支付状态更新延迟。
    </div>
    <div class="inner-footer">
      <a-button type="primary" :disabled="!list.length" :loading="loading" @click="onSubmitPay">支付</a-button>
    </div>
  </div>
</template>

<script>
import { fetchFrontDeposit, orderPaySubmit } from '@/api/order'
import { status as payStatus } from '@/filters/pay'

export default {
  data () {
    return {
      columns: [
        {
          title: '选择',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          align: 'center'
        },
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '押金支付时限',
          dataIndex: 'limit'
        },
        {
          title: '支付比例',
          dataIndex: 'payRate'
        },
        {
          title: '待支付金额',
          dataIndex: 'totalFee'
        },
        {
          title: '支付状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      list: [],
      payMethods: [
        {
          id: 'NNBILL_UP',
          title: '快钱网银',
          type: 'NNBILL',
          mode: 'UP'
        },
        {
          id: 'NNBILL_AC',
          title: '快钱账号余额',
          type: 'NNBILL',
          mode: 'AC'
        }
      ],
      checkPay: {},
      checkPayMethod: '',
      loading: false,
      detail: {}
    }
  },
  props: {
    orderNo: String
  },
  mounted () {
    this.fetchDeposit()
  },
  methods: {
    submit () {},
    async fetchDeposit (record) {
      this.loading = true
      try {
        const data = await fetchFrontDeposit({
          orderNo: this.orderNo
        })

        this.loading = false
        this.detail = data
        this.list = data.depositDetails || []
      } catch (err) {
        this.loading = false

        this.$message.error(err.message)
      }
    },
    onMethodClick (method) {
      this.checkPayMethod = method
    },
    onCheckPay (payRow) {
      this.checkPay = payRow
    },
    async onSubmitPay () {
      if (!this.checkPay.id) {
        this.$message.error('请选择支付条目')
        return
      }

      if (!this.checkPayMethod.type) {
        this.$message.error('请选择支付方式 ')
        return
      }

      this.loading = true

      try {
        const params = {
          relatedId: this.checkPay.id,
          action: 'TEAM_DEPOSIT',
          amount: this.checkPay.totalFee,
          bankId: '',
          currency: 'CNY',
          numOfPeriods: '',
          orderNo: this.orderNo,
          payMethod: 'NORMAL',
          payType: this.checkPayMethod.type,
          payMode: this.checkPayMethod.mode,
          payToReturnUrl: window.location.href,
          terminal: 'PC'
        }
        const result = await orderPaySubmit(params)
        this.loading = false

        if (result.payUrl) {
          window.open(result.payUrl)
        }

        this.$confirm({
          content: '确认是否支付？',
          okText: '我已支付成功',
          onOk: () => {
            location.reload()
          },
          cancelText: '重新继续支付',
          onCancel: () => {}
        })

        console.log(result)
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    }
  },
  filters: {
    payStatus
  }
}
</script>

<style lang="less" scoped>
.detail-deposit-pay-container {
  .base-info {
    margin-bottom: 10px;
  }

  .pay-tabs {
  }
  .deposit-pay-list {
    padding: 20px 0;
  }
  .deposit-pay {
    display: inline-block;
    border: 1px solid @joy-darkblue-color;
    padding: 10px;
    cursor: pointer;

    &.active {
      border: 1px solid @joy-orange-color;
    }
    + .deposit-pay {
      margin-left: 10px;
    }
  }
}
.ant-modal-footer {
  display: none;
}
</style>
