import { render, staticRenderFns } from "./detail-add.vue?vue&type=template&id=0892c451&scoped=true&"
import script from "./detail-add.vue?vue&type=script&lang=js&"
export * from "./detail-add.vue?vue&type=script&lang=js&"
import style0 from "./detail-add.vue?vue&type=style&index=0&id=0892c451&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0892c451",
  null
  
)

export default component.exports