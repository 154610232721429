<template>
  <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
    <a-form-item label="选择PNR编码">
      <a-select :options="pnrs" v-decorator="['pnrNo', { rules: [{ required: true, message: '请选择PNR' }] }]" @change="onChange"></a-select>
    </a-form-item>
    <a-form-item label="旅客姓名">
      <a-input v-decorator="['passengerName', { rules: [{ required: true, message: '请输入旅客姓名' }] }]"></a-input>
    </a-form-item>
    <a-form-item label="证件类型">
      <a-select :options="certificate" v-decorator="['certType', { rules: [{ required: true, message: '请选择PNR' }] }]"></a-select>
    </a-form-item>
    <a-form-item label="新证件号">
      <a-input v-decorator="['certNo', { rules: [{ required: true, message: '请输入证件号码' }] }]"></a-input>
    </a-form-item>
    <a-form-item label="备注">
      <a-textarea v-decorator="['mark']"></a-textarea>
    </a-form-item>
  </a-form>
</template>

<script>
import { modifyCertNo, fetchinVentoryDetail } from '@/api/order'
import { certType as certificate } from '@/dicts/passenger'

import _ from 'lodash'

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'modify-certnoo' }),
      pnrs: [],
      pnr: null,
      pnrNo: '',
      certificate
    }
  },
  props: {
    orderNo: String
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      const { orderNo } = this

      try {
        const { pnrDetails = [] } = await fetchinVentoryDetail({
          orderNo
        })

        if (!pnrDetails) {
          return
        }

        this.pnrs = pnrDetails.map(item => {
          return {
            value: item.id,
            label: item.pnr,
            cnt: item.cnt
          }
        })
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onChange (value) {
      const { pnrs } = this

      const pnr = this.pnr = _.find(pnrs, { value })

      this.pnrNo = pnr && pnr.label

      try {
        if (pnr) {
          this.form.setFieldsValue({
            cnt: pnr.cnt
          })
        }
      } catch (e) {

      }
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            const { orderNo } = this

            try {
              this.data = await modifyCertNo({
                orderNo,
                ...values,
                ...{
                  pnrNo: this.pnrNo
                }
              })
              this.$message.success('修改乘客证件号成功，即将刷新页面', function () {
                location.reload()
              })
            } catch (err) {
              this.loading = false
              this.$message.error(err.message)
              reject(new Error())
            }
          } else {
            reject(new Error())
          }
        })
      })
    }
  }
}
</script>
