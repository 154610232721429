<template>
  <div>
    <div class="cell-inner">
      <div class="inner-body">
        <a-descriptions :column="3" title="一键出票">
          <a-descriptions-item label="订单人数">
            {{ order.leftGroupQty }}
          </a-descriptions-item>
          <a-descriptions-item label="订单总金额">
            ￥{{ order.statisticInfo.totalOrderAmount }}
          </a-descriptions-item>
          <a-descriptions-item label="已支付">
            ￥{{ order.statisticInfo.totalPaidAmount }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="checkout-loading" v-if="loading">
            系统操作出票中...
        </div>
        <div>
            <a-alert v-if="error.visible" type="error" :message="error.message" banner />
        </div>
      </div>
    <div class="inner-footer" v-if="status !== 'PF'">
          <a-button type="primary" @click="onCheckout" :loading="loading">提交出票</a-button>
        </div>
    </div>
  </div>
</template>

<script>
import { orderCheckout } from '@/api/order'

export default {
  props: {
    order: Object,
    status: String
  },
  data: function () {
    return {
      loading: false,
      error: {
        visible: false,
        message: ''
      }
    }
  },
  methods: {
    onCheckout () {
      this.fetchCheckout()
    },
    async fetchCheckout () {
      this.loading = true
      this.error.visible = false

      const params = {
        airlineCode: this.$airSetttings.airline,
        orderNo: this.order.orderNo
      }

      try {
        const result = await orderCheckout(params)
        this.loading = false

        if (result) {
          this.$emit('success')
        } else {
          this.$emit('fail')
        }
      } catch (e) {
        this.$emit('fail')

        this.loading = false
        this.error.visible = true
        this.error.message = e.message
      }
    }
  }
}
</script>

<style lang="less" scoped>
.checkout-loading {
    color: @joy-orange-color;
}
</style>
