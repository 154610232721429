<template>
  <div class="split-ticket-container cell-inner">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
      <a-form-item label="当前订单人数">
        <a-input disabled v-decorator="['pnrs', {initialValue: count}]"></a-input>
      </a-form-item>
      <a-form-item label="分离出票人数">
        <a-input
          type="number"
          :min="0"
          :max="count"
          v-decorator="[
            'cnt',
            { rules: [{ required: true, message: '请选择分离出票数量' }] },
          ]"
        ></a-input>
      </a-form-item>
    </a-form>
    <div :style="{color: 'red'}">
      说明：分离后系统将自动从现有PNR中分离出一个新的子订单和PNR编码，请您尽快对分离出的子订单完成出票（子订单不能再次分离，也不能加人、减人，分离后请尽快出票）。
    </div>
  </div>
</template>

<script>
import { splitTicketIssue } from '@/api/order'

export default {
  data () {
    return {
      form: this.$form.createForm(this, { name: 'remove-people' }),
      pnrs: 0
    }
  },
  props: {
    orderNo: String,
    count: Number
  },
  created () {

  },
  methods: {
    submit () {
      return new Promise((resolve, reject) => {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            const { orderNo } = this

            try {
              this.data = await splitTicketIssue({
                orderNo,
                cnt: values.cnt
              })
              this.$message.success('编码分离成功，系统已为您生成一笔子订单，请尽快查看该订单并完成出票。', function () {
                location.reload()
              })
            } catch (err) {
              this.loading = false
              this.$message.error(err.message)
              reject(new Error())
            }
          } else {
            reject(new Error())
          }
        })
      })
    }
  }
}
</script>

<style>
</style>
